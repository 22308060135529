var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ax-batch-issues",attrs:{"fluid":""}},[_c('div',[_c('p',[_c('strong',[_vm._v("We’ve identified a few issues while processing your vulnerability report")])]),_c('p',[_vm._v(" Please review the following issues. In certain cases, upon creating tasks, these issues will result in Automox not being able to perform any action. Hover over issue types for more details. ")])]),_c('div',{staticClass:"tw-text-right"},[_c('ax-button',{staticClass:"tw-ml-4 tw-my-3",attrs:{"mode":"secondary","data-test-id":"batch-issues-export-csv-button","href":_vm.getBatchIssuesExportUrl(_vm.orgId, _vm.batchId),"aria-label":"Export CSV"}},[_c('v-icon',{staticClass:"tw-mr-2"},[_vm._v(_vm._s(_vm.mdiExportVariant))]),_vm._v(" Export CSV ")],1)],1),_c('ax-table',{attrs:{"headers":_vm.issuesHeader,"items":_vm.issues,"column-select":false,"options":_vm.tableState,"item-key":"ui_key","footer-props":_vm.issuesFooter,"server-items-length":_vm.serverItemsLength},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"item.ip_addrs_private",fn:function(ref){
var item = ref.item;
return [_c('ax-ip-address-column',{attrs:{"public-first":false,"ips":{
          publicAddresses: [],
          privateAddresses: item.ip_addrs_private || [],
        },"no-data-text":""}})]}},{key:"item.issue",fn:function(ref){
        var item = ref.item;
return [_c('ax-tooltip',{staticClass:"issue-tooltip",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.getIssueText(item.issue))+" ")])]}}],null,true)},[_c('div',{staticClass:"tw-pt-3"},[_c('span',{staticClass:"tw-uppercase tw-text-base"},[_vm._v(_vm._s(_vm.getIssueText(item.issue)))]),_c('p',{staticClass:"issue-text"},[_vm._v(_vm._s(_vm.getIssueDescription(item.issue)))])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }